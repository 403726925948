<template>
  <v-file-input
    class="text-capitalize"
    dense
    outlined
    show-size
    :accept="fileType"
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t(label)"
    :value="value"
    :rules="rules"
    :prepend-icon="icon"
    @change="file => $emit('input', file)"
  />
</template>

<script>
import { createValidationRules } from '@/utils'

export default {
  name: 'BaseFileInput',

  props: {
    label: String,
    icon: {
      type: String,
      default: '$image'
    },
    value: File,
    required: Boolean,
    sizeLimit: {
      type: Number,
      default: 10
    },
    fileType: {
      type: String,
      default: 'image/png, image/jpeg'
    }
  },

  computed: {
    rules() {
      return createValidationRules({
        label: this.label,
        required: this.required,
        sizeLimit: this.sizeLimit
      })
    }
  }
}
</script>

<style scoped></style>
