<template>
  <v-dialog ref="dialog" v-model="modal" width="290px">
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex">
        <v-text-field
          ref="field"
          :label="$t(label)"
          :value="dateFormatted"
          prepend-icon="$calendar"
          readonly
          clearable
          hide-details
          dense
          v-bind="attrs"
          v-on="on"
          @click:clear="clear"
        ></v-text-field>
        <BaseCheckbox
          v-if="showTypeSelection"
          hide-details
          class="ml-2 flex-shrink-0"
          label="common.month-picker"
          :value="monthPicker"
          @change="updateMonthPicker"
        />
      </div>
    </template>
    <v-date-picker
      :key="type"
      :type="type"
      :range="range"
      :allowed-dates="allowedDates"
      :value="value"
      @input="update"
      color="primary"
      :locale="$i18n.locale"
      :first-day-of-week="1"
    />
  </v-dialog>
</template>

<script>
import { formatDate } from '@/utils'
import { endOfToday } from 'date-fns'

export default {
  name: 'BaseDatePicker',

  props: {
    value: [String, Array],
    label: String,
    showTypeSelection: {
      type: Boolean,
      default: false
    },
    monthPicker: {
      type: Boolean,
      default: false
    },
    range: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    modal: false
  }),

  computed: {
    dateFormatted() {
      if (typeof this.value === 'string') {
        if (this.type === 'date') {
          return formatDate(new Date(this.value), 'E, dd MMM, yyyy')
        } else {
          return formatDate(new Date(this.value), 'MMMM, yyyy')
        }
      } else if (Array.isArray(this.value)) {
        let formattedArray = []
        this.value.forEach(date => {
          let dateAfterFormat
          const dateNormalized = new Date(date)
          if (this.type === 'date') {
            dateAfterFormat = formatDate(dateNormalized, 'dd MMM')
          } else {
            dateAfterFormat = formatDate(dateNormalized, 'MMM/yy')
          }
          const dateEntry = { label: dateAfterFormat, value: dateNormalized }
          formattedArray.push(dateEntry)
        })
        formattedArray.sort((a, b) => a.value - b.value)
        return formattedArray
          .map(date => {
            return date.label
          })
          .join(' ~ ')
      } else {
        return ''
      }
    },

    type() {
      if (this.monthPicker === true) {
        return 'month'
      } else {
        return 'date'
      }
    }
  },

  methods: {
    update(payload) {
      if (Array.isArray(payload)) {
        payload.sort()
      }
      this.$emit('input', payload)
      this.modal = false
    },

    clear() {
      this.update(null)
      this.$refs.field.blur()
    },

    allowedDates(val) {
      const date = new Date(val)
      return date < endOfToday()
    },

    updateMonthPicker(val) {
      this.$emit('update:monthPicker', val)
      if (val === false) {
        this.update(null)
      } else {
        this.update(this.value)
      }
    }
  }
}
</script>

<style scoped></style>
