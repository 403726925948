import { apiClientProtected } from '..'
import {
  startOfDay,
  startOfWeek,
  startOfMonth,
  endOfDay,
  endOfMonth,
  subDays,
  subWeeks,
  subMonths
} from 'date-fns'
import { setTimePeriod } from '@/utils'

const route = '/invoices'

export default {
  getList(conditions) {
    for (const key in conditions) {
      if (!conditions[key] && conditions[key] !== false) {
        conditions[key] = ''
      }
    }
    const { page, limit, search, status, date, byMonth } = conditions
    const { from, to } = setTimePeriod(date, byMonth)

    const checkNum = num => {
      let value
      if (Number.isInteger(num) && num > 0) {
        value = num
      } else {
        value = ''
      }
      return value
    }
    const selectedPage = checkNum(page)
    const selectedLimit = checkNum(limit)

    return apiClientProtected.get(
      `${route}/${status}?page=${selectedPage}&limit=${selectedLimit}&search=${search}&from=${from}&to=${to}`
    )
  },
  createInvoice(orderId) {
    return apiClientProtected.post(route, { orderId })
  },
  getInvoice(id) {
    return apiClientProtected.get(`${route}/${id}`)
  },
  updateInvoice(id, invoice) {
    return apiClientProtected.put(`${route}/${id}`, invoice)
  },
  cancelInvoice(id) {
    return apiClientProtected.delete(`${route}/${id}`)
  },
  getSummary(type, end, range) {
    let start, endFormatted
    const today = new Date(Date.now())

    if (end) {
      const endDate = new Date(end)
      if (type === 'monthly') {
        endFormatted = endOfMonth(endDate)
      } else {
        endFormatted = endDate
      }
    } else {
      endFormatted = today
    }

    if (type === 'daily') {
      start = startOfDay(subDays(endFormatted, range - 1))
    } else if (type === 'weekly') {
      start = startOfWeek(subWeeks(endFormatted, range - 1), {
        weekStartsOn: 1
      })
    } else {
      start = startOfMonth(subMonths(endFormatted, range - 1))
    }

    const from = start.toISOString()
    const to = endOfDay(endFormatted).toISOString()

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    return apiClientProtected.get(
      `${route}/summary?type=${type}&from=${from}&to=${to}&tz=${tz}`
    )
  }
}
