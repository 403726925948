<template>
  <v-row dense>
    <v-col cols="5"
      ><span>{{ $t(label) }}</span></v-col
    >
    <v-col cols="7">
      <span :class="`font-weight-medium line-break ${contentColor}`">{{
        content
      }}</span></v-col
    >
  </v-row>
</template>

<script>
export default {
  name: 'BaseEntry',

  props: {
    label: String,
    content: [String, Number],
    contentColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
