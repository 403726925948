<template>
  <div>
    <div
      v-if="$vuetify.breakpoint.smAndUp || showHeaderMobile"
      :class="`${mainHeaderColor || 'orange accent-4'} pa-2`"
    >
      <h1 :class="mainHeaderStyle">{{ $t(mainHeader) }}</h1>
    </div>
    <div v-if="subHeader || note" :class="subHeaderStyle">
      <p v-if="subHeader" :class="subHeaderTextColor">{{ $t(subHeader) }}</p>
      <p v-if="note" :class="noteInfoStyle">
        <slot name="note" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',

  props: {
    mainHeader: String,
    mainHeaderColor: String,
    mainHeaderTextColor: String,
    subHeader: String,
    subHeaderTextColor: String,
    note: String,
    showHeaderMobile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    mainHeaderStyle() {
      const baseStyle = `font-weight-bold text-center text-uppercase ${this
        .mainHeaderTextColor || 'white--text'}`
      if (this.$vuetify.breakpoint.mdAndUp) {
        return `${baseStyle} text-h4`
      } else {
        return `${baseStyle} text-h5`
      }
    },

    subHeaderStyle() {
      const baseStyle = 'pt-4 font-weight-bold text-center text-uppercase'
      if (this.$vuetify.breakpoint.mdAndUp) {
        return `${baseStyle} text-h5`
      } else {
        return `${baseStyle} text-subtitle-1`
      }
    },

    noteInfoStyle() {
      const baseStyle = 'my-0 text-body-2 font-italic'
      if (this.$vuetify.breakpoint.mdAndUp) {
        return `${baseStyle} text-right`
      } else {
        return `${baseStyle} text-center`
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
