<template>
  <v-btn
    icon
    v-bind="$attrs"
    @mousedown.stop
    @touchstart.stop
    @click.stop="$emit('action')"
  >
    <v-icon>${{ iconName }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseIconButton',
  props: {
    iconName: String
  }
}
</script>

<style scoped></style>
