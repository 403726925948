<template>
  <v-navigation-drawer dark v-model="drawer" app right v-on="$listeners">
    <v-list-item>
      <v-list-item-icon>
        <v-icon
          v-if="$vuetify.breakpoint.mdAndDown"
          color="deep-purple lighten-3"
          >$book</v-icon
        >
      </v-list-item-icon>
      <v-list-item-title
        class="text-body-1 text-uppercase font-weight-medium deep-purple--text text--lighten-3"
        >{{ $t('dish.menu') }}</v-list-item-title
      >
    </v-list-item>
    <v-divider />
    <v-list nav dense>
      <v-list-item-group v-model="item" color="primary">
        <NavBarItem
          :label="$t('dish.all-dishes')"
          icon="food"
          :to="{ name: 'dish-category', params: { category: 'all' } }"
          :class="$route.params.category === 'any' ? 'v-list-item--active' : ''"
        />
        <NavBarItem
          v-for="category in dishCategories"
          :key="category.code"
          :label="$i18n.locale === 'vi' ? category.viName : category.enName"
          :icon="category.categoryCode"
          exact
          :to="{
            name: 'dish-category',
            params: { category: category.categoryCode }
          }"
        />
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import NavBarItem from './NavBarItem'

export default {
  name: 'MenuBar',

  components: {
    NavBarItem
  },

  props: {
    value: Boolean
  },

  data: () => ({
    item: 0
  }),

  computed: {
    ...mapState('dish', ['dishCategories']),

    drawer: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped></style>
