<template>
  <div>
    <PageHeader
      main-header="common.restaurant-name"
      note="home.price-info"
      showHeaderMobile
    >
      <template v-slot:note v-if="!$auth.isAuthenticated">
        <span>{{ $t('home.price-info') }}</span
        ><br />
        <span>({{ $t('home.image-reference') }})</span>
      </template>
    </PageHeader>
    <router-view></router-view>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndDown"
      fixed
      color="primary"
      fab
      bottom
      right
      @click.stop="$mitt.emit('openQrScanDialog')"
    >
      <v-icon>$qrScan</v-icon>
    </v-btn>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'

export default {
  name: 'Home',

  components: {
    PageHeader
  }
}
</script>
