import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import { displayErrorMessage } from '@/utils'
import i18n from '@/i18n'
import goTo from 'vuetify/es5/services/goto'

import { authGuard } from '../auth/auth-guard'

Vue.use(VueRouter)

const NotFound = () => import('@/views/NotFound.vue')
const Maintenance = () => import('@/views/Maintenance.vue')
const Menu = () => import('@/views/Menu.vue')
const Kitchen = () => import('@/views/Kitchen.vue')
const Orders = () => import('@/views/Orders.vue')
const Summary = () => import('@/views/Summary.vue')
const KitchenRequestList = () =>
  import('@/components/kitchen-requests/KitchenRequestList.vue')
const OrderList = () => import('@/components/orders/OrderList.vue')
const InvoiceList = () => import('@/components/invoices/InvoiceList.vue')
const DishList = () => import('@/components/dishes/DishList.vue')
const SummaryTable = () => import('@/components/summary/SummaryTable.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'dish-category', params: { category: 'any' } }
  },
  {
    path: '/dishes',
    name: 'dishes',
    component: Home,
    redirect: { name: 'dish-category', params: { category: 'any' } },
    meta: { requiresAuth: false, showCategoryText: true },
    children: [
      {
        path: ':category',
        name: 'dish-category',
        component: DishList,
        props: route => ({
          category: route.params.category,
          searchTerm: route.query.search
        }),
        meta: { requiresAuth: false, showCategoryText: true },
        beforeEnter: (to, from, next) => {
          const { error } = to.query
          if (error) {
            displayErrorMessage(i18n.t(`error.${error}`))
          }
          next()
        }
      }
    ]
  },
  {
    path: '/kitchen',
    name: 'kitchen',
    component: Kitchen,
    redirect: { name: 'request-type', params: { type: 'cooking' } },
    meta: { collapsingTitle: true },
    children: [
      {
        path: ':type',
        name: 'request-type',
        component: KitchenRequestList,
        props: true
      }
    ]
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    redirect: { name: 'orders-serving' },
    meta: { collapsingTitle: true },
    children: [
      {
        path: 'serving',
        name: 'orders-serving',
        component: OrderList
      },
      {
        path: 'invoices',
        name: 'orders-invoices',
        component: InvoiceList,
        meta: { requiresCashier: true }
      },
      {
        path: 'cancelled',
        name: 'orders-cancelled',
        component: OrderList
      }
    ]
  },
  {
    path: '/summary',
    name: 'summary',
    component: Summary,
    redirect: { name: 'summary-type', params: { type: 'daily' } },
    meta: { requiresAdmin: true, collapsingTitle: true },
    children: [
      {
        path: ':type',
        name: 'summary-type',
        component: SummaryTable,
        props: true
      }
    ]
  },
  {
    path: '/menu',
    name: 'menu',
    component: Menu,
    meta: { requiresAdmin: true, collapsingTitle: true }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: { requiresAuth: false }
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    goTo(document.getElementById('app'))
  }
})

router.beforeEach(authGuard)

export default router
