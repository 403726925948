<template>
  <v-img
    v-if="image"
    v-bind="$attrs"
    v-on="$listeners"
    class="mb-6"
    height="200"
    :src="image"
    @error="showDefaultImg"
  />
</template>

<script>
export default {
  name: 'BaseImagePreview',

  props: {
    imgUrl: String,
    imgFile: File
  },

  data: () => ({
    defaultImg: null
  }),

  computed: {
    image() {
      if (this.imgFile) {
        return URL.createObjectURL(this.imgFile)
      } else {
        return this.defaultImg || this.imgUrl
      }
    }
  },

  methods: {
    showDefaultImg() {
      this.$emit('error')
      this.defaultImg = require('@/assets/placeholder.jpeg')
    }
  }
}
</script>

<style scoped></style>
