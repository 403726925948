<template>
  <v-dialog v-model="dialog" scrollable :max-width="maxWidth">
    <v-card>
      <v-card-title class="text-capitalize d-flex justify-space-between">
        <div>{{ $t(title) }}</div>
        <v-icon
          v-if="topIcon"
          color="secondary"
          @click.stop="$emit('selectTopIcon')"
          >${{ topIcon }}
        </v-icon>
      </v-card-title>
      <v-card-text class="pb-0 text-body-1 black--text">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="pl-6">
        <v-icon
          v-if="botIcon"
          color="secondary"
          @click.stop="$emit('selectBotIcon')"
          >${{ botIcon }}
        </v-icon>
        <v-spacer />
        <v-btn color="secondary" text @click.stop="cancel">{{
          $t('common.back')
        }}</v-btn>
        <v-btn
          v-if="actionable"
          color="primary"
          text
          :disabled="!validity"
          :loading="loader"
          @click.stop="$emit('confirm')"
        >
          {{ $t(actionButton) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',

  props: {
    propDialog: Boolean,
    title: String,
    validity: {
      type: Boolean,
      default: true
    },
    loader: Boolean,
    actionButton: {
      type: String,
      default: 'common.confirm'
    },
    topIcon: String,
    botIcon: String,
    maxWidth: {
      type: Number,
      default: 600
    },
    actionable: {
      type: Boolean,
      default: true
    },
    goBack: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dialog: {
      get() {
        return this.propDialog
      },
      set(val) {
        this.$emit('closeDialog', val)
      }
    }
  },

  methods: {
    cancel() {
      if (this.goBack) {
        this.$emit('cancel')
      } else {
        this.dialog = false
      }
    }
  }
}
</script>

<style scoped></style>
