import { apiClientUnprotected, apiClientProtected } from '..'

export default {
  getDishCategories() {
    return apiClientUnprotected.get('/dish-categories')
  },
  getTables() {
    return apiClientProtected.get('/tables')
  }
}
