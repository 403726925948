import SettingsService from '@/services/modules/SettingsService'
import MiscService from '@/services/modules/MiscService'
import { displayErrorMessage } from '@/utils'

export const namespaced = true

export const state = {
  runCampaign: null,
  campaignDetails: null,
  restaurantInfo: null,
  showDishImage: null,
  defaultDishes: null,
  latestClientBuild: null,
  hasNewBuild: false,
  extras: null,
  locale: null,
  selectedDate: null,
  rangeType: null,
  tables: null
}

export const mutations = {
  SET_SETTINGS(state, settings) {
    state = Object.assign(state, settings)
  },

  SET_LOCALE(state, locale) {
    state.locale = locale
  },

  SET_AUTO_RANGE(state, { type, date }) {
    state.rangeType = type
    state.selectedDate = date
  },

  SET_HAS_NEW_BUILD(state) {
    state.hasNewBuild = true
  },

  SET_TABLES(state, tables) {
    state.tables = tables
  },

  REPLACE_TABLE(state, table) {
    const index = state.tables.findIndex(x => x.tableCode === table.tableCode)
    state.tables.splice(index, 1, table)
  }
}

export const actions = {
  fetchSettings({ commit }) {
    SettingsService.get()
      .then(res => commit('SET_SETTINGS', res.data))
      .catch(err => displayErrorMessage(err))
  },

  fetchTables({ commit }) {
    MiscService.getTables()
      .then(res => commit('SET_TABLES', res.data))
      .catch(err => {
        displayErrorMessage(err)
      })
  },

  updateTable({ commit }, { tableCode, orderInfo }) {
    const oldTable = state.tables.find(x => x.tableCode === tableCode)
    const newTable = { ...oldTable, orderInfo }
    commit('REPLACE_TABLE', newTable)
  },

  socket_refreshSettings({ commit, rootState }, data) {
    commit('SET_SETTINGS', data)
    commit('order/CLEAR_BASKET', null, { root: true })
    const dishes = rootState.dish.initDishes
    commit('dish/SET_DISHES', dishes, { root: true })
  },

  socket_refreshTableList({ commit }, data) {
    commit('SET_TABLES', data)
  }
}

export const getters = {
  tableCount: state => {
    if (Array.isArray(state.tables)) {
      return state.tables.length
    } else {
      return null
    }
  }
}
