<template>
  <v-switch
    class="mt-0 mb-6"
    v-bind="$attrs"
    hide-details
    inset
    v-on="$listeners"
    :label="$t(label)"
    :input-value="value"
    :rules="rules"
    @change="val => $emit('input', val)"
  />
</template>

<script>
import { createValidationRules } from '@/utils'

export default {
  name: 'BaseSwitch',

  props: {
    label: String,
    value: Boolean,
    required: Boolean
  },

  computed: {
    rules() {
      return createValidationRules({
        label: this.label,
        required: this.required
      })
    }
  }
}
</script>

<style scoped></style>
