<template>
  <v-icon
    v-bind="$attrs"
    v-on="$listeners"
    @mousedown.stop="startHold"
    @touchstart.stop="startHold"
    @mouseleave="endHold"
    @mouseup="endHold"
    @touchend="endHold"
    @click.stop="doAction"
    >${{ iconName }}
  </v-icon>
</template>

<script>
export default {
  name: 'BaseIcon',

  props: {
    iconName: String,
    quantity: Number
  },

  data: () => ({
    counter: null,
    repeater: null,
    timeout: 500,
    interval: 100
  }),

  watch: {
    quantity(val) {
      if (val === 0) {
        this.endHold()
      }
    }
  },

  methods: {
    doAction() {
      this.$emit('action')
    },

    startHold() {
      document.getElementsByTagName('body')[0].classList.add('no-select')
      this.counter = setTimeout(() => {
        this.repeater = setInterval(this.doAction, this.interval)
      }, this.timeout)
    },

    endHold() {
      document.getElementsByTagName('body')[0].classList.remove('no-select')
      clearTimeout(this.counter)
      clearInterval(this.repeater)
    }
  }
}
</script>

<style></style>
