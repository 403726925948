<template>
  <v-autocomplete
    ref="multiSelect"
    class="text-capitalize"
    outlined
    small-chips
    deletable-chips
    multiple
    menu-props="closeOnContentClick"
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t(label)"
    :value="value"
    :rules="rules"
    :no-data-text="$t(noData)"
    @change="blurWhenChange"
  />
</template>

<script>
import { createValidationRules } from '@/utils'

export default {
  name: 'BaseDropdown',

  props: {
    label: String,
    noData: String,
    value: Array,
    required: Boolean
  },

  computed: {
    rules() {
      return createValidationRules({
        label: this.label,
        required: this.required
      })
    }
  },

  methods: {
    blurWhenChange() {
      this.$refs.multiSelect.blur()
    }
  }
}
</script>

<style scoped></style>
