import MiscService from '@/services/modules/MiscService'
import DishService from '@/services/modules/DishService'
import {
  displaySuccessMessage,
  displayErrorMessage,
  adjustOptionPrice,
  reprocessDish
} from '@/utils'
import cloneDeep from 'lodash/cloneDeep'

export const namespaced = true

export const state = {
  dishCategories: null,
  initDishes: null,
  dishes: null,
  activeOptions: null
}

export const mutations = {
  SET_DISH_CATEGORIES(state, categories) {
    state.dishCategories = categories
  },

  SET_INIT_DISHES(state, dishes) {
    state.initDishes = dishes
  },

  SET_DISHES(state, dishes) {
    const processedDishes = cloneDeep(dishes)
    let activeOptions = []
    processedDishes.forEach(dish => {
      const adjustedDish = reprocessDish(dish)
      const { serving, options } = adjustedDish
      if (serving) {
        activeOptions.push(...options)
      }
    })
    state.dishes = processedDishes
    state.activeOptions = activeOptions
  },

  CREATE_DISH(state, dish) {
    const adjustedDish = reprocessDish(dish)
    state.dishes.unshift(adjustedDish)
  },

  UPDATE_DISH(state, dish) {
    const index = state.dishes.findIndex(x => x._id === dish._id)
    const adjustedDish = reprocessDish(dish)
    state.dishes.splice(index, 1, adjustedDish)
  },

  DELETE_DISH(state, dish) {
    const index = state.dishes.findIndex(x => x._id === dish._id)
    state.dishes.splice(index, 1)
  },

  ADD_OPTION(state, option) {
    const index = state.dishes.findIndex(x => x.dishCode === option.parentCode)
    const updatedDish = state.dishes[index]
    const adjustedOption = adjustOptionPrice(option, updatedDish.priceTBD)
    updatedDish.options.push(adjustedOption)
  },

  UPDATE_OPTION(state, option) {
    const index = state.dishes.findIndex(x => x.dishCode === option.parentCode)
    const updatedDish = state.dishes[index]
    const adjustedOption = adjustOptionPrice(option, updatedDish.priceTBD)
    const optionIndex = updatedDish.options.findIndex(x => x._id === option._id)
    updatedDish.options.splice(optionIndex, 1, adjustedOption)
  },

  DELETE_OPTION(state, option) {
    const index = state.dishes.findIndex(x => x.dishCode === option.dishCode)
    const updatedDish = state.dishes[index]
    const optionIndex = updatedDish.options.findIndex(x => x._id === option._id)
    updatedDish.options.splice(optionIndex, 1)
  }
}

export const actions = {
  fetchDishCategories({ commit }) {
    MiscService.getDishCategories()
      .then(res => commit('SET_DISH_CATEGORIES', res.data))
      .catch(err => displayErrorMessage(err))
  },

  fetchDishes({ commit }) {
    return new Promise((resolve, reject) => {
      DishService.getFullList()
        .then(res => {
          const dishes = res.data
          commit('SET_INIT_DISHES', dishes)
          commit('SET_DISHES', dishes)
          resolve()
        })
        .catch(err => reject(err))
    })
  },

  createDish({ commit }, form) {
    return new Promise((resolve, reject) => {
      DishService.createDish(form)
        .then(res => {
          commit('CREATE_DISH', res.data)
          displaySuccessMessage('dish.message-create-dish')
          this._vm.$socket.client.emit('update-dish', res.data.dishCode)
          resolve()
        })
        .catch(err => reject(err))
    })
  },

  updateDish({ commit }, { id, form }) {
    return new Promise((resolve, reject) => {
      DishService.updateDish(id, form)
        .then(res => {
          commit('UPDATE_DISH', res.data)
          commit('order/CLEAR_BASKET', null, { root: true })
          displaySuccessMessage('dish.message-update-dish')
          this._vm.$socket.client.emit('update-dish', res.data.dishCode)
          resolve()
        })
        .catch(err => reject(err))
    })
  },

  deleteDish({ commit }, dish) {
    return new Promise((resolve, reject) => {
      DishService.deleteDish(dish._id)
        .then(res => {
          commit('DELETE_DISH', res.data)
          commit('order/CLEAR_BASKET', null, { root: true })
          this._vm.$socket.client.emit('update-dish', res.data.dishCode)
          displaySuccessMessage('dish.message-delete-dish')
          resolve()
        })
        .catch(err => reject(err))
    })
  },

  addOption({ commit }, { dishCode, option }) {
    return new Promise((resolve, reject) => {
      DishService.addOption(dishCode, option)
        .then(res => {
          commit('ADD_OPTION', res.data)
          displaySuccessMessage('dish.message-add-option')
          this._vm.$socket.client.emit('update-dish')
          resolve()
        })
        .catch(err => reject(err))
    })
  },

  updateOption({ commit }, { optionId, option }) {
    return new Promise((resolve, reject) => {
      DishService.updateOption(optionId, option)
        .then(res => {
          commit('UPDATE_OPTION', res.data)
          commit('order/CLEAR_BASKET', null, { root: true })
          displaySuccessMessage('dish.message-update-option')
          this._vm.$socket.client.emit('update-dish', res.data.dishCode)
          resolve()
        })
        .catch(err => reject(err))
    })
  },

  deleteOption({ commit }, optionId) {
    return new Promise((resolve, reject) => {
      DishService.deleteOption(optionId)
        .then(res => {
          commit('DELETE_OPTION', res.data)
          commit('order/CLEAR_BASKET', null, { root: true })
          displaySuccessMessage('dish.message-update-option')
          this._vm.$socket.client.emit('update-dish', res.data.dishCode)
          resolve()
        })
        .catch(err => reject(err))
    })
  }
}
