<template>
  <BaseDialog
    :propDialog="dialog"
    :title="dialogTitle"
    topIcon="delete"
    :botIcon="authenticated ? 'box' : 'login'"
    :validity="validity"
    :loader="loading"
    :actionable="authenticated"
    @selectTopIcon="selectTopIcon"
    @selectBotIcon="selectBotIcon"
    @confirm="submitRequest"
    @closeDialog="setDialog"
  >
    <div
      v-if="!$auth.loading && !authenticated"
      class="d-flex flex-column justify-center font-weight-medium text-center"
    >
      <div class="text-center primary--text text-body-1">
        {{ $t('order.qr-instruction') }}
      </div>
      <VueQr
        v-if="$socket.connected"
        class="align-self-center"
        :text="`request-basket.${$socket.client.id}`"
      ></VueQr>
      <div
        v-if="$socket.disconnected"
        class="text-center deep-purple--text text-body-2 font-italic my-2"
      >
        {{ $t('order.qr-not-visible') }}
      </div>
      <BaseIconButton
        v-if="$socket.disconnected"
        class="align-self-center"
        icon-name="refresh"
        @action="$socket.client.connect()"
      />
    </div>
    <div v-if="authenticated" class="sticky-el--form">
      <OrderDetails
        v-if="dialog"
        :tableCode.sync="tableCode"
        :customerName.sync="customerName"
        :isValid.sync="valid"
        :orderId.sync="orderId"
      />
    </div>
    <NewRequestItemList :valid.sync="itemListValid" />
  </BaseDialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import NewRequestItemList from './NewRequestItemList'
import OrderDetails from '../OrderDetails'
import KitchenRequestService from '@/services/modules/KitchenRequestService'
import OrderService from '@/services/modules/OrderService'
import {
  createInvoice,
  displaySuccessMessage,
  displayErrorMessage
} from '@/utils'
import VueQr from 'vue-qr'

export default {
  name: 'NewRequest',

  components: {
    NewRequestItemList,
    OrderDetails,
    VueQr
  },

  props: {
    dialog: Boolean
  },

  data: () => ({
    newOrder: null,
    loading: false,
    button: false,
    valid: false,
    itemListValid: true,
    tableCode: null,
    customerName: '',
    orderId: null
  }),

  computed: {
    ...mapState('order', ['basket']),
    ...mapState('common', ['tables', 'extras', 'defaultDishes']),
    ...mapState('dish', ['activeOptions']),
    ...mapGetters('order', ['orderTotal']),

    validity() {
      if (this.orderTotal && this.valid && this.itemListValid) {
        return true
      } else {
        return false
      }
    },

    authenticated() {
      return !this.$auth.loading && this.$auth.isAuthenticated
    },

    dialogTitle() {
      if (this.orderId) {
        return 'order.more-requests'
      } else if (this.tableCode) {
        return 'order.create-order'
      } else {
        return 'order.place-order'
      }
    }
  },

  watch: {
    orderTotal() {
      if (this.orderTotal === 0) {
        this.$emit('update:dialog', false)
        this.tableCode = null
        this.orderId = null
        this.customerName = ''
      }
    }
  },

  methods: {
    ...mapActions('order', ['addToBasket']),
    ...mapActions('common', ['updateTable']),

    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    selectTopIcon() {
      this.$store.commit('order/CLEAR_BASKET')
      this.$notify(this.$t('order.message-remove-from-order'))
    },

    navigate(routeName) {
      this.$router
        .push({ name: routeName })
        .then()
        .catch(err => {
          throw err
        })
    },

    async submitRequest() {
      this.loading = true
      const submit = () => {
        if (this.orderId) {
          return this.updateOrder()
        } else {
          return this.createOrder()
        }
      }
      try {
        const order = await submit()
        let routeName
        if (this.tableCode === 'takeaway') {
          await createInvoice(order._id)
          routeName = 'orders-invoices'
        } else {
          const { _id, customerName, createdAt } = order
          const orderInfo = { orderId: _id, customerName, createdAt }
          this.updateTable({ tableCode: this.tableCode, orderInfo })
          this.$socket.client.emit('update-table', this.tables)
          displaySuccessMessage('order.message-submit-order')
          routeName = 'orders-serving'
        }
        if (this.$route.name === routeName) {
          this.$mitt.emit('refreshList')
        } else {
          this.navigate(routeName)
        }
        this.$store.commit('order/CLEAR_BASKET')
        this.$socket.client.emit('update-request')
      } catch (err) {
        displayErrorMessage(err)
      } finally {
        this.loading = false
      }
    },

    createOrder() {
      let basket = [...this.basket]
      if (this.tableCode !== 'takeaway' && Array.isArray(this.defaultDishes)) {
        for (const defaultDishCode of this.defaultDishes) {
          const hasDefaultDish = this.basket.some(
            request => request.parentCode === defaultDishCode
          )
          if (!hasDefaultDish) {
            const defaultDish = this.activeOptions.find(
              option => option.parentCode === defaultDishCode
            )
            const defaultRequest = { ...defaultDish, quantity: 1 }
            delete defaultRequest._id
            basket.push(defaultRequest)
          }
        }
      }
      return new Promise((resolve, reject) => {
        OrderService.submitOrder(this.tableCode, this.customerName, basket)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },

    updateOrder() {
      return new Promise((resolve, reject) => {
        KitchenRequestService.createKitchenRequests(this.orderId, this.basket)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },

    login() {
      this.$loading.show()
      this.$auth.loginWithRedirect()
    },

    selectBotIcon() {
      if (this.authenticated) {
        this.addToBasket(this.extras.foodContainer)
      } else {
        this.login()
      }
    }
  }
}
</script>
