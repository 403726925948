import AES from 'crypto-js/aes'
import { getNewVal } from '@/utils'
import { v4 as uuidv4 } from 'uuid'

export const namespaced = true

export const state = {
  basket: []
}

const saveToLocal = basket => {
  const basketString = JSON.stringify(basket)
  const basketEncrypted = AES.encrypt(
    basketString,
    process.env.VUE_APP_CRYPTO_KEY
  ).toString()
  sessionStorage.setItem('basketToken', basketEncrypted)
}

export const mutations = {
  ADD_TO_BASKET(state, request) {
    state.basket.push(request)
    saveToLocal(state.basket)
  },

  INCREASE_QUANTITY(state, index) {
    const d = state.basket[index]
    const { quantity, inKilogram } = d
    d.quantity = getNewVal(quantity, true, inKilogram)
    state.basket.splice(index, 1, d)
    saveToLocal(state.basket)
  },

  REMOVE_FROM_BASKET(state, { index, clear }) {
    const d = state.basket[index]
    const { quantity, inKilogram } = d
    if (
      clear ||
      (quantity === 1 && !inKilogram) ||
      (quantity === 1 / 10 && inKilogram)
    ) {
      state.basket.splice(index, 1)
    } else {
      d.quantity = getNewVal(quantity, false, inKilogram)
      state.basket.splice(index, 1, d)
    }
    saveToLocal(state.basket)
  },

  REPLACE_IN_BASKET(state, { index, updatedOrderedDish }) {
    state.basket.splice(index, 1, updatedOrderedDish)
    saveToLocal(state.basket)
  },

  PULL_BASKET(state, basket) {
    state.basket = basket
    saveToLocal(state.basket)
  },

  CLEAR_BASKET(state) {
    state.basket.splice(0, state.basket.length)
    saveToLocal(state.basket)
  }
}

export const actions = {
  addToBasket({ commit }, orderedDish) {
    const { dishCode, viName, enName } = orderedDish
    let request = { ...orderedDish }
    request.ogViName = viName
    request.ogEnName = enName
    if (dishCode === 'mon-theo-yeu-cau') {
      request.dishCode = `${dishCode}-${uuidv4()}`
    }
    const index = state.basket.findIndex(x => x.dishCode === request.dishCode)
    if (index < 0) {
      request.quantity = request.inKilogram ? 1 / 10 : 1
      request.note = ''
      request.showNote = false
      delete request._id
      commit('ADD_TO_BASKET', request)
    } else {
      commit('INCREASE_QUANTITY', index)
    }
  },

  increaseQuantity({ commit }, orderedDish) {
    const index = state.basket.findIndex(
      x => x.dishCode === orderedDish.dishCode
    )
    commit('INCREASE_QUANTITY', index)
  },

  removeFromBasket({ commit }, orderedDish) {
    const index = state.basket.findIndex(
      x => x.dishCode === orderedDish.dishCode
    )
    commit('REMOVE_FROM_BASKET', { index })
  },

  updateOrderedDish({ commit }, { orderedDish, key, value }) {
    const { parentCode, dishCode, viName, enName } = orderedDish
    const index = state.basket.findIndex(x => x.dishCode === dishCode)
    const updatedOrderedDish = { ...orderedDish }
    let nameAppend = ''
    if (parentCode === 'mon-theo-yeu-cau' && key === 'note') {
      if (value) {
        nameAppend = ` - ${value}`
      }
    }
    updatedOrderedDish.viName = `${viName}${nameAppend}`
    updatedOrderedDish.enName = `${enName}${nameAppend}`
    updatedOrderedDish[key] = value
    commit('REPLACE_IN_BASKET', { index, updatedOrderedDish })
  },

  toggleNote({ commit }, orderedDish) {
    const index = state.basket.findIndex(
      x => x.dishCode === orderedDish.dishCode
    )
    const { ogViName, ogEnName, showNote } = orderedDish
    const toggleNote = !showNote
    const updatedOrderedDish = {
      ...orderedDish,
      note: '',
      showNote: toggleNote,
      viName: ogViName,
      enName: ogEnName
    }
    commit('REPLACE_IN_BASKET', { index, updatedOrderedDish })
  },

  socket_refreshDishList({ commit }, dishCode) {
    const index = state.basket.findIndex(
      request => request.dishCode === dishCode
    )
    if (index > -1) {
      commit('REMOVE_FROM_BASKET', { index, clear: true })
    }
  }
}

export const getters = {
  orderTotal: state => {
    let orderTotal = state.basket
    const reducer = (acc, item) => {
      let increment
      if (item.inKilogram) {
        increment = 1
      } else {
        increment = item.quantity
      }
      return acc + increment
    }
    const total = orderTotal.reduce(reducer, 0)
    return total
  }
}
