<template>
  <v-app>
    <v-app-bar app dark color="#363636" height="56">
      <v-app-bar-nav-icon
        @click.stop="leftDrawer = !leftDrawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        v-if="
          $vuetify.breakpoint.mdAndDown &&
            $route.matched.some(record => record.meta.showCategoryText)
        "
        :class="toolbarClass"
        >{{ categoryHeader }}
      </v-toolbar-title>
      <v-toolbar-title
        v-else-if="
          $vuetify.breakpoint.xsOnly &&
            $route.matched.some(record => record.meta.collapsingTitle)
        "
        :class="toolbarClass"
        >{{ $t(collapsingTitle) }}</v-toolbar-title
      >
      <v-btn
        v-if="$auth.isAuthenticated && $vuetify.breakpoint.lgAndUp"
        color="primary"
        @click.stop="openQrScanDialog"
        class="ml-2"
      >
        <v-icon>$qrScan</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        :disabled="!orderTotal"
        @click.stop="initNewOrder"
        class="mr-2"
      >
        <v-icon left>
          $cart
        </v-icon>
        {{ orderTotal }}
      </v-btn>
      <v-app-bar-nav-icon @click.stop="rightDrawer = !rightDrawer"
        ><v-icon>$book</v-icon></v-app-bar-nav-icon
      >
    </v-app-bar>
    <NavBar
      v-model="leftDrawer"
      @transitionend="$mitt.emit('toggleDrawer')"
      @openRequestDialog="initNewOrder"
    />
    <MenuBar
      v-model="rightDrawer"
      @transitionend="$mitt.emit('toggleDrawer')"
    />
    <v-main class="grey lighten-4">
      <v-container fluid>
        <v-card min-height="calc(100vh - 90px)">
          <v-container fluid>
            <router-view :key="$route.fullPath" />
          </v-container>
        </v-card>
      </v-container>
      <NewRequest :dialog.sync="newRequestDialog" />
      <QrScan v-if="qrScanDialog" :dialog.sync="qrScanDialog" />
      <BaseDialog
        :propDialog="confirmDialog"
        :title="confirmTitle"
        :loader="confirmLoading"
        @confirm="onConfirm"
        @closeDialog="setConfirmDialog"
        ><div class="text-center">{{ confirmContent }}</div>
      </BaseDialog>
      <notifications
        classes="my-style"
        position="bottom center"
        ignoreDuplicates
      />
      <v-snackbar v-model="snackbar" :timeout="-1">
        {{ $t(snackbarText) }}
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="reloadPage">
            {{ $t('common.reload') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import AES from 'crypto-js/aes'
import encUtf8 from 'crypto-js/enc-utf8'
import { mapState, mapGetters } from 'vuex'
import NavBar from '@/components/nav-bar/NavBar'
import MenuBar from '@/components/nav-bar/MenuBar'
import NewRequest from '@/components/orders/NewRequest/NewRequest'
import cloneDeep from 'lodash/cloneDeep'
import forEachRight from 'lodash/forEachRight'
import {
  displayErrorMessage,
  updateInvoice,
  createInvoice,
  updateRequest,
  cancelOrder
} from '@/utils/'

const QrScan = () => import('@/components/common/QrScan')

export default {
  name: 'App',

  components: {
    NavBar,
    MenuBar,
    NewRequest,
    QrScan
  },

  data: () => ({
    optionContainer: null,
    newRequestDialog: false,
    qrScanDialog: false,
    leftDrawer: null,
    rightDrawer: null,
    confirmDialog: false,
    confirmLoading: false,
    confirmTitle: '',
    confirmContent: '',
    confirmPayload: null,
    confirmItem: null,
    snackbar: false,
    snackbarText: null,
    dishParam: null,
    toolbarClass: 'primary--text font-weight-medium text-capitalize pl-0 ml-2',
    disconnected: false
  }),

  computed: {
    ...mapState('common', ['hasNewBuild']),
    ...mapState('dish', ['dishCategories', 'activeOptions']),
    ...mapState('order', ['basket']),
    ...mapGetters('order', ['orderTotal']),
    ...mapGetters('common', ['tableCount']),

    collapsingTitle() {
      const checkBranch = branchName => {
        return this.$route.matched.some(record => record.name === branchName)
      }
      if (checkBranch('kitchen')) {
        return 'nav-bar.kitchen'
      } else if (checkBranch('orders')) {
        return 'nav-bar.orders'
      } else if (checkBranch('summary')) {
        return 'nav-bar.summary'
      } else if (checkBranch('menu')) {
        return 'nav-bar.menu'
      } else {
        return ''
      }
    },

    categoryHeader() {
      if (this.dishCategory) {
        if (this.$i18n.locale === 'vi') {
          return this.dishCategory.viName
        } else {
          return this.dishCategory.enName
        }
      } else {
        return this.$t('dish.all-dishes')
      }
    },

    dishCategory() {
      if (!this.dishCategories || !this.dishParam) {
        return null
      } else {
        return this.dishCategories.find(x => x.categoryCode === this.dishParam)
      }
    },

    isNotAuthenticated() {
      return (
        !this.$auth.loading &&
        !this.$auth.isAuthenticated &&
        this.$auth.auth0Client
      )
    }
  },

  mounted() {
    this.$mitt.on('openConfirmDialog', this.openConfirmDialog)
    this.$mitt.on('openQrScanDialog', this.openQrScanDialog)
    this.$mitt.on('networkError', this.displaySnackbar)
    this.$store.commit('common/SET_LOCALE', this.$i18n.locale)
  },
  beforeDestroy() {
    this.$mitt.off('openConfirmDialog', this.openConfirmDialog)
    this.$mitt.off('openQrScanDialog', this.openQrScanDialog)
    this.$mitt.off('networkError', this.displaySnackbar)
  },

  watch: {
    $route(to, from) {
      sessionStorage.setItem('recentPage', to.fullPath)
      if (this.hasNewBuild) {
        this.reloadPage()
      } else if (to.name === 'dish-category') {
        this.dishParam = to.params.category
        if (
          this.dishParam === 'any' &&
          this.newRequestDialog === false &&
          this.qrScanDialog === false
        ) {
          this.rightDrawer = true
        }
      } else {
        this.rightDrawer = false
      }

      if (
        from.matched.some(record => record.path === '/orders') &&
        !to.matched.some(record => record.path === '/orders')
      ) {
        this.$store.commit('common/SET_AUTO_RANGE', {
          type: null,
          date: null
        })
      }
    },

    newRequestDialog(val) {
      if (val) {
        sessionStorage.setItem('newRequestDialog', '1')
      } else {
        sessionStorage.removeItem('newRequestDialog')
      }
    },

    qrScanDialog(val) {
      if (val) {
        sessionStorage.setItem('qrScanDialog', '1')
      } else {
        sessionStorage.removeItem('qrScanDialog')
      }
    },

    tableCount(val) {
      if (val) {
        this.validateBasket()
      }
    },

    '$auth.isAuthenticated'(val) {
      if (val) {
        this.$socket.client.emit('authenticated')
        sessionStorage.setItem('authenticated', '1')
        this.$store.dispatch('common/fetchTables')
      }
    },

    isNotAuthenticated(val) {
      const authenticated = sessionStorage.getItem('authenticated')
      const recentPage = sessionStorage.getItem('recentPage')
      if (val && authenticated) {
        sessionStorage.removeItem('authenticated')
        this.$auth.loginWithRedirect({
          appState: { targetUrl: recentPage }
        })
      }
    },

    '$socket.disconnected'(val) {
      if (val) {
        this.disconnected = true
      }
    }
  },

  methods: {
    initNewOrder() {
      this.newRequestDialog = true
      this.$mitt.emit('initNewOrder')
    },

    openQrScanDialog() {
      this.qrScanDialog = true
    },

    decrypt(token) {
      const bytes = AES.decrypt(token, process.env.VUE_APP_CRYPTO_KEY)
      return bytes.toString(encUtf8)
    },

    displaySnackbar(message) {
      this.snackbarText = message
      this.snackbar = true
    },

    displayPreviousDialog(dialogName) {
      const previousState = sessionStorage.getItem(dialogName)
      if (previousState) {
        this[dialogName] = true
      }
    },

    pullLocalBasket(basketToken) {
      if (basketToken) {
        const decryptedData = this.decrypt(basketToken)
        if (decryptedData) {
          this.$store.commit('order/PULL_BASKET', JSON.parse(decryptedData))
        }
      }
    },

    setConfirmDialog() {
      this.confirmDialog = false
    },

    async onConfirm() {
      this.confirmLoading = true
      const { type } = this.confirmPayload

      const cancelRequest = async () => {
        const { orderId, isClosedOrder } = this.confirmItem
        this.confirmItem.status = 'cancelled'
        await updateRequest(this.confirmItem, 'cancelRequest')
        if (isClosedOrder) {
          await createInvoice(orderId)
        }
      }

      const confirm = () => {
        if (type === 'cancelRequest') {
          return cancelRequest()
        } else if (type === 'cancelOrder') {
          this.confirmItem.status = 'cancelled'
          return cancelOrder(this.confirmItem)
        } else if (type === 'deleteDish') {
          return this.$store.dispatch('dish/deleteDish', this.confirmItem)
        } else if (type === 'createInvoice') {
          return createInvoice(this.confirmItem)
        } else if (type === 'cancelInvoice') {
          this.confirmItem.status = 'cancelled'
          return updateInvoice(this.confirmItem._id, this.confirmItem)
        }
      }
      try {
        await confirm()
        this.confirmDialog = false
      } catch (err) {
        displayErrorMessage(err)
      } finally {
        this.confirmLoading = false
      }
    },

    openConfirmDialog(item) {
      const { data, confirmTitle, confirmContent } = item
      this.confirmItem = cloneDeep(data)
      this.confirmTitle = confirmTitle
      this.confirmContent = confirmContent
      this.confirmPayload = item
      this.confirmDialog = true
    },

    reloadPage() {
      location.reload()
    },

    validateBasket() {
      this.pullLocalBasket(sessionStorage.getItem('basketToken'))
      this.displayPreviousDialog('newRequestDialog')
      forEachRight(this.basket, (request, index) => {
        const masterOption = this.activeOptions.find(
          option => option.dishCode === request.dishCode
        )
        if (
          (!masterOption ||
            masterOption.dishCode !== request.dishCode ||
            masterOption.price !== request.price) &&
          !this.$auth.isAuthenticated
        ) {
          this.$store.commit('order/REMOVE_FROM_BASKET', { index, clear: true })
        }
      })
    }
  },

  sockets: {
    connect() {
      if (this.disconnected) {
        const authenticated = sessionStorage.getItem('authenticated')
        if (authenticated) {
          sessionStorage.removeItem('authenticated')
          this.$auth.loginWithRedirect({
            appState: { targetUrl: this.$route.fullPath }
          })
        } else {
          this.reloadPage()
        }
      } else {
        this.displayPreviousDialog('qrScanDialog')
        this.$store.dispatch('dish/fetchDishCategories')
        this.$store.dispatch('common/fetchSettings')
        this.$store
          .dispatch('dish/fetchDishes')
          .then()
          .catch(err => displayErrorMessage(err))
      }
    },

    newBuild() {
      this.displaySnackbar('common.new-build-notif')
      this.$store.commit('common/SET_HAS_NEW_BUILD')
    },

    qrBasketRequest(receiverId) {
      this.$socket.client.emit('send-basket', receiverId, this.basket)
      this.newRequestDialog = false
      this.$store.commit('order/CLEAR_BASKET')
      this.$notify(this.$t('order.message-submit-order'))
    },

    qrBasket(basket) {
      this.$store.commit('order/PULL_BASKET', basket)
      this.qrScanDialog = false
      this.newRequestDialog = true
      this.$notify(this.$t('order.message-add-to-order'))
    },

    invalidQr() {
      this.qrScanDialog = false
      displayErrorMessage(this.$t('error.qrNotValid'))
    },

    refreshDishList() {
      this.$store.dispatch('dish/fetchDishes')
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.my-style {
  padding: 10px;
  margin: 10px 5px 5px;

  font-size: 14px;

  color: #ffffff;
  background: map-get($blue, lighten-1) !important;
  border-left: 5px solid map-get($blue, darken-2);

  &.notif-warn {
    background: map-get($amber, lighten-1) !important;
    border-left-color: map-get($amber, darken-2);
  }

  &.notif-error {
    background: map-get($red, lighten-1) !important;
    border-left-color: map-get($red, darken-2);
  }

  &.notif-success {
    background: map-get($green, lighten-1) !important;
    border-left-color: map-get($green, darken-2);
  }
}

.v-pagination__item:focus {
  outline: none !important;
}

.v-input__prepend-outer {
  margin-top: 4px;
  margin-bottom: -2px;
}

.sticky-el {
  position: sticky;
  top: 0;
  z-index: 1;
  width: auto;
  background: rgba(255, 255, 255, 0.95);
  &--title {
    @extend .sticky-el;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 8px;
    margin-right: -24px;
    margin-left: -24px;
  }
  &--form {
    @extend .sticky-el--title;
    padding-top: 12px;
    margin-top: -12px;
    padding-bottom: 0;
  }
  &--menu {
    @extend .sticky-el;
    top: 56px;
    padding: 0px 12px 0px 12px;
    margin: 0px -12px 12px -12px;
  }
  &--submenu {
    @extend .sticky-el--menu;
    top: 104px;
  }
}

.line-break {
  white-space: pre-line;
}

.v-data-table-header-mobile {
  display: none;
}

.data-table thead th {
  background-color: map-get($grey, darken-2) !important;
  color: white !important;
  text-transform: capitalize;
}

.data-table tbody tr:nth-of-type(odd) td {
  background-color: map-get($amber, lighten-5);
}

.data-table tbody tr:nth-of-type(even) td {
  background-color: map-get($deep-purple, lighten-5);
}

.data-table tbody tr:nth-of-type(odd):hover td {
  background-color: map-get($amber, lighten-4);
}

.data-table tbody tr:nth-of-type(even):hover td {
  background-color: map-get($deep-purple, lighten-4);
}

.list-loader {
  position: relative;
  height: 10vh;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
}

.clickable {
  cursor: pointer;
}

.fit-text .v-btn__content {
  width: 100%;
}
</style>
