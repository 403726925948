<template>
  <v-text-field
    ref="textField"
    class="text-capitalize"
    dense
    outlined
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t(label)"
    :value="value"
    :rules="rules"
    @keydown="blurWhenEnter"
  />
</template>

<script>
import { createValidationRules } from '@/utils'

export default {
  name: 'BaseTextInput',

  props: {
    label: String,
    value: String,
    required: Boolean,
    characterLimit: {
      type: Number,
      required: true
    }
  },

  computed: {
    rules() {
      return createValidationRules({
        label: this.label,
        required: this.required,
        characterLimit: this.characterLimit
      })
    }
  },

  methods: {
    blurWhenEnter(e) {
      if (e.key === 'Enter') {
        this.$refs.textField.blur()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
