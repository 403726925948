<template>
  <div>
    <v-row
      v-if="searchField || filterCount"
      :class="isSticky"
      v-intersect="onIntersect"
    >
      <v-col
        v-if="searchField"
        cols="12"
        sm="6"
        :md="fullScreen ? '4' : false"
        class="d-flex"
      >
        <v-text-field
          :ref="`search-${type}`"
          class="mr-4"
          :label="$t('common.search')"
          :value="searchTerm"
          prepend-icon="$edit"
          clearable
          single-line
          hide-details
          dense
          @input="search"
          @keydown="checkInput"
          @click:clear="resetSearch"
        />
        <v-btn
          fab
          small
          color="primary"
          :disabled="!searchTerm"
          @click.stop="$emit('doSearch')"
        >
          <v-icon dark>
            $magnify
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-for="i in filterCount"
        :key="i"
        cols="12"
        sm="6"
        :md="fullScreen ? '4' : false"
      >
        <slot :name="`filter${i}`"></slot>
      </v-col>
    </v-row>
    <v-pagination
      v-if="pagination && !noData"
      ref="topPagination"
      :value="page"
      :length="totalPages"
      total-visible="10"
      @input="changePage"
    />
    <slot></slot>
    <div v-if="isLoading" class="list-loader">
      <Loading
        :active.sync="isLoading"
        :is-full-page="false"
        :z-index="2"
        loader="dots"
      ></Loading>
    </div>
    <div :hidden="!noData" class="text-h6 text-center my-6">
      {{ $t(noDataText) }}
    </div>
    <v-pagination
      v-if="pagination && !noData"
      :value="page"
      :length="totalPages"
      total-visible="10"
      @input="changePage"
    />
    <v-btn
      v-if="showUp && !isIntersecting"
      fixed
      color="primary"
      fab
      bottom
      right
      @click.stop="$helper.scrollTop"
      ><v-icon :size="32">$up</v-icon></v-btn
    >
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'

export default {
  name: 'BaseList',

  props: {
    searchTerm: String,
    page: Number,
    totalPages: Number,
    noData: Boolean,
    noDataText: String,
    searchField: {
      type: Boolean,
      default: true
    },
    filterCount: Number,
    fullScreen: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Boolean,
      default: true
    },
    type: String,
    isLoading: Boolean
  },

  components: {
    Loading
  },

  data: () => ({
    isIntersecting: true
  }),

  computed: {
    isSticky() {
      if (this.fullScreen && !this.filterCount) {
        if (this.type === 'dish') {
          return 'sticky-el--menu'
        } else {
          return 'sticky-el--submenu'
        }
      }
      return ''
    },

    showUp() {
      return this.fullScreen && this.filterCount
    }
  },

  watch: {
    totalPages(val) {
      if (val < this.page) {
        this.$emit('update:page', val)
      }
    }
  },

  methods: {
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },

    search(payload) {
      this.$emit('update:searchTerm', payload)
    },

    changePage(payload) {
      this.$emit('update:page', payload)
      scrollIntoView(this.$refs[`search-${this.type}`].$el)
    },

    checkInput(e) {
      if (e.key === 'Enter') {
        this.$emit('doSearch')
        this.$refs[`search-${this.type}`].blur()
      }
    },

    resetSearch() {
      this.$emit('update:searchTerm', '')
      this.$emit('doSearch')
    }
  }
}
</script>

<style scoped></style>
