import goTo from 'vuetify/es5/services/goto'

export default {
  setBgColor(status) {
    if (['waiting', 'hasWaiting', 'pending'].includes(status)) {
      return 'amber lighten-4'
    } else if (status === 'cooking') {
      return 'pink lighten-4'
    } else if (['ready', 'serving'].includes(status)) {
      return 'blue lighten-4'
    } else if (['delivered', 'closed', 'paid'].includes(status)) {
      return 'green lighten-4'
    } else {
      return 'grey lighten-2'
    }
  },

  formattedDate(time, noDate) {
    const options = {
      day: '2-digit',
      literal: '/',
      month: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }

    if (noDate) {
      delete options.day
      delete options.literal
      delete options.month
    }

    const formatted = new Intl.DateTimeFormat('en-gb', options).format(
      time ? new Date(time) : new Date()
    )
    return formatted
  },

  scrollTop() {
    goTo(document.getElementById('app'))
  }
}
