<template>
  <v-list-item v-bind="$attrs" v-on="$listeners">
    <v-list-item-icon>
      <v-icon :color="iconColor">${{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title :class="titleText">{{ label }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavBarItem',

  props: {
    label: String,
    icon: String,
    iconColor: String,
    textColor: String
  },

  computed: {
    titleText() {
      return `text-subtitle-2 text-capitalize ${this.textColor}`
    }
  }
}
</script>

<style scoped></style>
