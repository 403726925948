<template>
  <v-select
    ref="select"
    clearable
    hide-details
    dense
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t(label)"
    :value="value"
    @mousedown="$refs.select.focus()"
    @change="$refs.select.blur()"
  ></v-select>
</template>

<script>
export default {
  name: 'BaseFilter',

  props: {
    label: String,
    noData: String,
    value: [String, Boolean]
  }
}
</script>

<style scoped></style>
