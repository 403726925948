import axios from 'axios'
import i18n from '@/i18n.js'
import { getInstance } from '@/auth'
import NProgress from 'nprogress'
import Router from '@/router'

export const apiClientProtected = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT_V1,
  withCredentials: true
})

export const apiClientUnprotected = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT_V1,
  withCredentials: true
})

const resIntercept = res => {
  NProgress.done()
  return res
}

const resInterceptErr = err => {
  NProgress.done()
  const path = '/maintenance'
  if (
    err.response &&
    err.response.status === 503 &&
    Router.currentRoute.path != path
  ) {
    Router.push({ path }).catch(err => console.log(err))
  }
  return Promise.reject(err)
}

apiClientUnprotected.interceptors.request.use(config => {
  config.headers['Accept-Language'] = i18n.locale
  NProgress.start()
  return config
})

apiClientUnprotected.interceptors.response.use(
  res => resIntercept(res),
  err => resInterceptErr(err)
)

apiClientProtected.interceptors.request.use(async config => {
  try {
    const retrieveToken = () => {
      return new Promise((resolve, reject) => {
        const instance = getInstance()
        const getToken = () => {
          return instance
            .getTokenSilently()
            .then(token => resolve(token))
            .catch(err => reject(err))
        }

        if (!instance.loading) {
          return getToken()
        }

        instance.$watch('loading', loading => {
          if (loading === false && instance.isAuthenticated) {
            return getToken()
          }
        })
      })
    }

    const token = await retrieveToken()
    config.headers['Authorization'] = `Bearer ${token}`
    config.headers['Accept-Language'] = i18n.locale
    NProgress.start()
    return config
  } catch (err) {
    console.log(err)
    return config
  }
})

apiClientProtected.interceptors.response.use(
  res => resIntercept(res),
  err => resInterceptErr(err)
)
