<template>
  <div class="d-flex">
    <v-btn
      class="flex-shrink-1 fit-text"
      block
      v-bind="$attrs"
      @mousedown.stop
      @touchstart.stop
      @click.stop="$emit('action')"
    >
      <v-icon v-if="iconName" left>${{ iconName }}</v-icon>
      <span class="text-truncate">
        {{ $t(label) }}
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BaseBlockButton',
  props: {
    iconName: String,
    label: String
  }
}
</script>

<style scoped></style>
