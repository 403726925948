import Vue from 'vue'
import Vuex from 'vuex'
import * as dish from '@/store/modules/dish.js'
import * as order from '@/store/modules/order.js'
import * as common from '@/store/modules/common.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    dish,
    order,
    common
  }
})
