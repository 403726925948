<template>
  <v-currency-field
    ref="currencyField"
    class="text-capitalize"
    dense
    :suffix="currency"
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t(label)"
    :value="value"
    :rules="rules"
    @keydown="blurWhenEnter"
  />
</template>

<script>
import { createValidationRules } from '@/utils'

export default {
  name: 'BaseCurrencyInput',

  props: {
    label: String,
    value: Number,
    required: Boolean,
    currency: String
  },

  computed: {
    rules() {
      return createValidationRules({
        label: this.label,
        required: this.required
      })
    }
  },

  methods: {
    blurWhenEnter(e) {
      if (e.key === 'Enter') {
        this.$refs.currencyField.$children[0].blur()
      }
    }
  }
}
</script>

<style scoped></style>
