<template>
  <v-form
    :value="valid"
    @input="val => $emit('update:valid', val)"
    @submit.prevent
  >
    <BaseListToggle
      v-for="(dish, index) in basket"
      :key="index"
      :item="dish"
      quantityKey="quantity"
      :nameKey="$i18n.locale === 'vi' ? 'viName' : 'enName'"
      valueKey="price"
      noteKey="note"
      noteToggle="showNote"
      :canEdit="$auth.isAuthenticated"
      @increase="increaseQuantity"
      @decrease="removeFromBasket"
      @updateValue="onUpdateValue"
      @toggleNote="onToggleNote"
      @update:valid="val => $emit('update:valid', val)"
    />
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'NewRequestItemList',

  props: {
    valid: Boolean
  },

  computed: {
    ...mapState('order', ['basket'])
  },

  methods: {
    ...mapActions('order', ['increaseQuantity', 'removeFromBasket']),

    onUpdateValue(payload) {
      const { item, key, value } = payload
      this.$store.dispatch('order/updateOrderedDish', {
        orderedDish: item,
        key,
        value
      })
    },

    onToggleNote(item) {
      this.$store.dispatch('order/toggleNote', item)
    }
  }
}
</script>

<style></style>
