import { apiClientUnprotected, apiClientProtected } from '..'

const route = '/settings'

export default {
  get() {
    return apiClientUnprotected.get(route)
  },
  update(settings) {
    return apiClientProtected.put(route, settings)
  }
}
