<template>
  <v-row align="center" dense>
    <v-col cols="12"><v-divider color="grey"/></v-col>
    <v-col class="d-flex justify-end" cols="2" sm="1" order="3">
      <BaseToggleQuantity
        large
        :disabled="!canToggle"
        color="secondary"
        :quantity="quantity"
        @action="$emit('decrease', item)"
        iconName="minus"
      />
    </v-col>
    <v-col class="d-flex justify-center" cols="3" sm="2" order="4">
      <v-chip color="primary" outlined>{{ quantityText }} </v-chip>
    </v-col>
    <v-col class="d-flex justify-start" cols="2" sm="1" order="5">
      <BaseToggleQuantity
        large
        :disabled="!canToggle"
        color="primary"
        :quantity="quantity"
        @action="$emit('increase', item)"
        iconName="plus"
      />
    </v-col>
    <v-col
      class="font-weight-medium black--text"
      :cols="noteKey ? '10' : '12'"
      :sm="noteKey ? '4' : '5'"
      order="1"
      >{{ item[nameKey] }}</v-col
    >
    <v-col class="d-flex justify-end" cols="5" sm="3" order="6">
      <BaseCurrencyInput
        rounded
        filled
        hide-details
        :value="item[valueKey]"
        @input="updateValue"
        required
        :readonly="!canEdit"
      />
    </v-col>
    <v-col
      v-if="noteKey"
      class="d-flex justify-end"
      cols="2"
      sm="1"
      order="2"
      order-sm="7"
    >
      <v-icon
        large
        :color="item[noteToggle] ? 'amber' : ''"
        @click.stop="$emit('toggleNote', item)"
        >$note
      </v-icon>
    </v-col>
    <v-col v-if="item[noteToggle]" cols="12" order="8">
      <BaseTextInput
        hide-details
        :value="item[noteKey]"
        @change="updateNote"
        label="common.note"
        :character-limit="99"
      />
    </v-col>
  </v-row>
</template>

<script>
import { displayQuantity } from '@/utils'

export default {
  name: 'BaseListToggle',

  props: {
    item: Object,
    quantityKey: String,
    nameKey: String,
    valueKey: String,
    noteToggle: String,
    noteKey: String,
    canEdit: Boolean,
    canToggle: { type: Boolean, default: true }
  },

  computed: {
    quantity() {
      return this.item[this.quantityKey]
    },

    quantityText() {
      return displayQuantity(this.quantity, this.item.inKilogram)
    }
  },

  methods: {
    updateValue(val) {
      this.emitUpdate(this.valueKey, val)
    },

    updateNote(val) {
      this.emitUpdate(this.noteKey, val)
    },

    emitUpdate(key, value) {
      this.$emit('updateValue', { item: this.item, key, value })
    }
  }
}
</script>

<style></style>
