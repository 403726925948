import { apiClientProtected } from '..'

const route = '/kitchen-requests'

export default {
  getList(param) {
    return apiClientProtected.get(`${route}/${param}`)
  },
  createKitchenRequests(orderId, dishes) {
    return apiClientProtected.post(route, {
      orderId,
      dishes
    })
  },
  updateKitchenRequest(id, request) {
    return apiClientProtected.put(`${route}/${id}`, request)
  }
}
