<template>
  <v-select
    class="text-capitalize"
    dense
    outlined
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t(label)"
    :value="value"
    :rules="rules"
    :no-data-text="$t(noData)"
  />
</template>

<script>
import { createValidationRules } from '@/utils'

export default {
  name: 'BaseDropdown',

  props: {
    label: String,
    noData: String,
    value: String,
    required: Boolean
  },

  computed: {
    rules() {
      return createValidationRules({
        label: this.label,
        required: this.required
      })
    }
  }
}
</script>

<style scoped></style>
