import { getInstance } from './index'
import Vue from 'vue'
import { displayErrorMessage } from '@/utils'
import i18n from '@/i18n.js'

export const authGuard = (to, from, next) => {
  const loader = Vue.$loading.show()

  const authService = getInstance()

  const freePath = to.matched.some(record => record.meta.requiresAuth === false)
  const cashierPath = to.matched.some(record => record.meta.requiresCashier)
  const adminPath = to.matched.some(record => record.meta.requiresAdmin)

  const checkAuthPath = requiredRole => {
    const user = authService.user
    const roles = user[process.env.VUE_APP_AUTH_ROLES]
    const hasRole = roles.some(x => x === requiredRole)
    if (hasRole) {
      next()
    } else {
      displayErrorMessage(i18n.t('error.unauthorized'))
      next({ path: '/' })
    }
  }

  const fn = () => {
    if (freePath) {
      next()
    } else if (!authService.isAuthenticated) {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    } else if (cashierPath) {
      checkAuthPath('Cashier')
    } else if (adminPath) {
      checkAuthPath('Admin')
    } else {
      next()
    }
    loader.hide()
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}
