<template>
  <v-navigation-drawer dark v-model="drawer" app v-on="$listeners">
    <v-list-item>
      <v-list-item-avatar v-if="$auth.isAuthenticated">
        <v-icon color="deep-purple lighten-3">$user</v-icon>
      </v-list-item-avatar>
      <v-list-item-title
        v-if="$auth.isAuthenticated"
        class="text-body-1 text-capitalize font-weight-medium deep-purple--text text--lighten-3"
        >{{ $auth.user.name }}</v-list-item-title
      >
      <v-list-item-avatar>
        <v-btn
          icon
          :ripple="false"
          @click="changeLang"
          id="no-background-hover"
        >
          <CountryFlag :country="flag[$i18n.locale]" rounded />
        </v-btn>
      </v-list-item-avatar>
    </v-list-item>
    <v-divider />
    <v-list v-if="!$auth.loading" nav dense>
      <v-list v-if="orderTotal" class="pt-0">
        <v-list-item
          class="text-center"
          @click.stop="$emit('openRequestDialog')"
        >
          <v-list-item-icon>
            <v-icon>$textBox</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-subtitle-2 text-capitalize">{{
            $t('order.new-request-title')
          }}</v-list-item-title>
          <v-list-item-icon>
            <v-icon @click.stop="$store.commit('order/CLEAR_BASKET')"
              >$delete</v-icon
            >
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-for="(dish, index) in basket"
          :key="index"
          class="amber--text lighten-4 text-center"
        >
          <v-list-item-icon>
            <BaseToggleQuantity
              :quantity="dish.quantity"
              @action="removeFromBasket(dish)"
              iconName="minusOutline"
          /></v-list-item-icon>
          <v-list-item-title
            >{{ displayQt(dish.quantity, dish.inKilogram) }} -
            {{
              $i18n.locale === 'vi' ? dish.viName : dish.enName
            }}</v-list-item-title
          ><v-list-item-icon>
            <BaseToggleQuantity
              :quantity="dish.quantity"
              @action="increaseQuantity(dish)"
              iconName="plusOutline"/></v-list-item-icon
        ></v-list-item>
        <v-divider />
      </v-list>
      <v-list-item-group v-model="item" color="primary" class="mb-1">
        <NavBarItem
          :label="$t('nav-bar.home')"
          icon="home"
          :to="{ name: 'dishes' }"
        />
        <NavBarItem
          v-if="$auth.isAuthenticated"
          :label="$t('nav-bar.kitchen')"
          icon="cook"
          :to="{ name: 'kitchen' }"
        />
        <NavBarItem
          v-if="$auth.isAuthenticated"
          :label="$t('nav-bar.orders')"
          icon="order"
          :to="{ name: 'orders' }"
        />
        <NavBarItem
          v-if="admin"
          :label="$t('nav-bar.summary')"
          icon="calculator"
          :to="{ name: 'summary' }"
        />
        <NavBarItem
          v-if="admin"
          :label="$t('nav-bar.menu')"
          icon="noteBook"
          :to="{ name: 'menu' }"
        />
      </v-list-item-group>
      <NavBarItem
        :label="$t('nav-bar.vcb-name')"
        icon="bank"
        textColor="green--text text--lighten-3"
        iconColor="green lighten-3"
      />
      <NavBarItem
        :label="$t('nav-bar.vcb-num')"
        icon="copy"
        textColor="green--text text--lighten-3"
        iconColor="green lighten-3"
        @click="copyToClipboard($t('nav-bar.vcb-num'))"
      />
      <NavBarItem
        href="tel:+84 904 134 392"
        class="text-decoration-none"
        :label="$t('nav-bar.contact-num')"
        icon="phone"
        textColor="blue--text text--lighten-3"
        iconColor="blue lighten-3"
      />
      <NavBarItem
        v-if="!$auth.isAuthenticated"
        :label="$t('nav-bar.login')"
        icon="login"
        @click="login"
      />
      <NavBarItem
        v-if="$auth.isAuthenticated"
        :label="$t('nav-bar.logout')"
        icon="logout"
        textColor="deep-purple--text text--lighten-3"
        iconColor="deep-purple lighten-3"
        @click="logout"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CountryFlag from 'vue-country-flag'
import NavBarItem from './NavBarItem'
import { hasRole, displayQuantity } from '@/utils'

export default {
  name: 'NavBar',

  components: {
    CountryFlag,
    NavBarItem
  },

  props: {
    value: Boolean
  },

  data: () => ({
    item: 0,
    flag: { en: 'gb', vi: 'vn' }
  }),

  computed: {
    ...mapState('order', ['basket']),
    ...mapGetters('order', ['orderTotal']),

    drawer: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    admin() {
      if (this.$auth.isAuthenticated) {
        return hasRole('Admin')
      } else {
        return false
      }
    }
  },

  methods: {
    ...mapActions('order', ['increaseQuantity', 'removeFromBasket']),
    // Log the user in
    login() {
      this.$loading.show()
      this.$auth.loginWithRedirect()
    },
    // Log the user out
    logout() {
      this.$loading.show()
      sessionStorage.removeItem('authenticated')
      this.$auth.logout({
        returnTo: window.location.origin
      })
    },

    changeLang() {
      if (this.$i18n.locale === 'vi') {
        this.$i18n.locale = 'en'
        localStorage.setItem('lang', 'en')
      } else {
        this.$i18n.locale = 'vi'
        localStorage.setItem('lang', 'vi')
      }
      this.$store.commit('common/SET_LOCALE', this.$i18n.locale)
    },

    copyToClipboard(value) {
      this.$clipboard(value)
      this.$notify(this.$t('common.message-copy'))
    },

    displayQt(qt, inKilogram) {
      return displayQuantity(qt, inKilogram)
    }
  }
}
</script>

<style scoped>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
