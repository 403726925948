import { apiClientProtected, apiClientUnprotected } from '..'

const dishRoute = '/dishes'
const optionRoute = `${dishRoute}/option`

export default {
  getFullList() {
    return apiClientUnprotected.get(`${dishRoute}/full`)
  },
  getList(conditions) {
    for (const key in conditions) {
      if (!conditions[key] && conditions[key] !== false) {
        conditions[key] = ''
      }
    }
    const { page, limit, search, category, serving } = conditions
    return apiClientUnprotected.get(
      `${dishRoute}?page=${page}&limit=${limit}&search=${search}&category=${category}&serving=${serving}`
    )
  },
  createDish(dish) {
    return apiClientProtected.post(dishRoute, dish)
  },
  getDish(id) {
    return apiClientProtected.get(`${dishRoute}/${id}`)
  },
  updateDish(id, dish) {
    return apiClientProtected.put(`${dishRoute}/${id}`, dish)
  },
  deleteDish(id) {
    return apiClientProtected.delete(`${dishRoute}/${id}`)
  },
  addOption(groupId, option) {
    return apiClientProtected.post(`${optionRoute}/${groupId}`, option)
  },
  updateOption(groupId, option) {
    return apiClientProtected.put(`${optionRoute}/${groupId}`, option)
  },
  deleteOption(id) {
    return apiClientProtected.delete(`${optionRoute}/${id}`)
  }
}
