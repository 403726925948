import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { colors } from 'vuetify/lib'
import {
  mdiHome,
  mdiMinusCircle,
  mdiMinusCircleOutline,
  mdiPlusCircle,
  mdiPlusCircleOutline,
  mdiImage,
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiBookOpenPageVariant,
  mdiLoginVariant,
  mdiLogoutVariant,
  mdiNoteTextOutline,
  mdiRotate3dVariant,
  mdiClipboardTextOutline,
  mdiCashMultiple,
  mdiFood,
  mdiTextBoxPlusOutline,
  mdiAccountCircle,
  mdiCalendar,
  mdiCoffeeToGo,
  mdiBookmarkCheck,
  mdiPrinter,
  mdiCreditCard,
  mdiCellphone,
  mdiNotebook,
  mdiBowlMix,
  mdiFire,
  mdiPotSteam,
  mdiGrill,
  mdiCheese,
  mdiFoodDrumstick,
  mdiRice,
  mdiNoodles,
  mdiCorn,
  mdiBottleSoda,
  mdiGlassCocktail,
  mdiSmoking,
  mdiSilverwareVariant,
  mdiBank,
  mdiContentCopy,
  mdiQrcodeScan,
  mdiRefresh,
  mdiCalculator,
  mdiCashRemove,
  mdiQrcode,
  mdiTextBoxOutline,
  mdiTextBoxMultipleOutline,
  mdiCartVariant,
  mdiHamburgerPlus,
  mdiTune,
  mdiClipboardPlusOutline,
  mdiClipboardEditOutline,
  mdiSpoonSugar,
  mdiArchivePlusOutline,
  mdiArrowUp,
  mdiEye,
  mdiEyeOff,
  mdiReceiptTextCheckOutline
} from '@mdi/js'

Vuetify.config.silent = process.NODE_ENV === 'production' ? true : false
Vue.use(Vuetify)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      home: mdiHome,
      minus: mdiMinusCircle,
      minusOutline: mdiMinusCircleOutline,
      plus: mdiPlusCircle,
      plusOutline: mdiPlusCircleOutline,
      image: mdiImage,
      magnify: mdiMagnify,
      pencil: mdiPencil,
      delete: mdiDelete,
      cook: mdiBowlMix,
      cooking: mdiFire,
      delivering: mdiCoffeeToGo,
      delivered: mdiBookmarkCheck,
      book: mdiBookOpenPageVariant,
      login: mdiLoginVariant,
      logout: mdiLogoutVariant,
      note: mdiNoteTextOutline,
      rotate: mdiRotate3dVariant,
      order: mdiClipboardTextOutline,
      money: mdiCashMultiple,
      food: mdiFood,
      moreRequests: mdiTextBoxPlusOutline,
      user: mdiAccountCircle,
      calendar: mdiCalendar,
      print: mdiPrinter,
      card: mdiCreditCard,
      phone: mdiCellphone,
      noteBook: mdiNotebook,
      hotpot: mdiPotSteam,
      bbq: mdiGrill,
      sidedish: mdiCheese,
      entree: mdiFoodDrumstick,
      rice: mdiRice,
      noodles: mdiNoodles,
      vegetarian: mdiCorn,
      drink: mdiBottleSoda,
      alcohol: mdiGlassCocktail,
      cigarettes: mdiSmoking,
      silverware: mdiSilverwareVariant,
      bank: mdiBank,
      copy: mdiContentCopy,
      qr: mdiQrcode,
      qrScan: mdiQrcodeScan,
      refresh: mdiRefresh,
      calculator: mdiCalculator,
      cancelledPayment: mdiCashRemove,
      textBox: mdiTextBoxOutline,
      receipts: mdiTextBoxMultipleOutline,
      cart: mdiCartVariant,
      addDish: mdiHamburgerPlus,
      adjust: mdiTune,
      createOrder: mdiClipboardPlusOutline,
      updateOrder: mdiClipboardEditOutline,
      other: mdiSpoonSugar,
      box: mdiArchivePlusOutline,
      up: mdiArrowUp,
      show: mdiEye,
      hide: mdiEyeOff,
      receipt: mdiReceiptTextCheckOutline
    }
  },
  theme: {
    themes: {
      light: {
        primary: colors.orange.accent4
      }
    }
  }
})

export default vuetify
