<template>
  <BaseDialog
    :max-width="600"
    :propDialog="dialog"
    :title="$t('order.select-table')"
    :actionButton="$t('order.takeaway')"
    @confirm="selectTakeaway"
    @closeDialog="setDialog"
  >
    <v-tabs grow show-arrows height="36" class="sticky-el--title">
      <v-tab @click="setTableGroup(null)">{{ $t('common.all') }}</v-tab>
      <v-tab @click="setTableGroup('inside')">{{ $t('common.inside') }}</v-tab>
      <v-tab @click="setTableGroup('outside')">{{
        $t('common.outside')
      }}</v-tab>
      <v-tab @click="setTableGroup('other')">{{ $t('common.other') }}</v-tab>
    </v-tabs>
    <div ref="topTableList" />
    <v-row
      ><v-col
        cols="6"
        sm="4"
        v-for="table in filteredList"
        :key="table.tableCode"
        ><BaseBlockButton
          class="text-capitalize"
          :color="getColor(table)"
          :label="getName(table)"
          :disabled="table.disabled"
          @action="selectTable(table)"
        ></BaseBlockButton>
      </v-col>
    </v-row>
  </BaseDialog>
</template>

<script>
import { getTableName } from '@/utils'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'

export default {
  name: 'SelectTable',

  props: {
    dialog: Boolean,
    tableList: Array
  },

  data: () => ({
    tableGroup: null
  }),

  computed: {
    filteredList() {
      if (!this.tableGroup) {
        return this.tableList
      } else {
        const filtered = this.tableList.filter(
          table => table.groupCode === this.tableGroup
        )
        return filtered
      }
    }
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    setTableGroup(val) {
      scrollIntoView(this.$refs.topTableList)
      this.tableGroup = val
    },

    checkServing(table) {
      if (table.orderInfo) {
        return true
      } else {
        return false
      }
    },

    getName(table) {
      return getTableName(table)
    },

    getColor(table) {
      const isServing = this.checkServing(table)
      if (isServing) {
        return 'purple lighten-4'
      } else {
        return 'amber lighten-4'
      }
    },

    selectTable(table) {
      this.$emit('selectTable', table)
      this.setDialog(false)
    },

    selectTakeaway() {
      this.$emit('selectTakeaway')
      this.setDialog(false)
    }
  }
}
</script>

<style scoped></style>
