<template>
  <div :class="!isOrder && orderId ? 'mb-4 pb-4' : ''">
    <v-row dense>
      <v-col
        v-if="!isInvoice"
        :cols="showExtraInputs ? 7 : 12"
        :sm="showExtraInputs ? 4 : false"
        class="py-0"
      >
        <BaseBlockButton
          :color="selectTableColor"
          :label="tableName"
          @action="openSelectTable"
        />
      </v-col>
      <v-col
        v-if="!isInvoice && showExtraInputs"
        cols="5"
        sm="4"
        class="py-0 d-flex justify-center"
        ><BaseCheckbox
          :disabled="isOrder"
          label="order.takeaway"
          v-model="takeaway"
          @click="toggleTakeaway()"
        />
      </v-col>
      <v-col v-if="isInvoice" col="12" sm="6" class="py-0"
        ><BaseTextInput
          label="order.table"
          :value="invoiceTableName"
          :character-limit="30"
          required
          @input="val => $emit('update:invoiceTableName', val)"
      /></v-col>
      <v-col
        v-if="showExtraInputs"
        cols="12"
        :sm="!isInvoice ? '4' : '6'"
        class="py-0"
      >
        <BaseTextInput
          label="order.customer"
          :value="customerName"
          :character-limit="30"
          @input="val => $emit('update:customerName', val)"
        />
      </v-col>
      <v-col v-if="isInvoice" cols="12">
        <BaseTextInput
          label="common.note"
          :value="note"
          :character-limit="99"
          @input="val => $emit('update:note', val)"
        />
      </v-col>
    </v-row>
    <SelectTable
      :dialog.sync="tableDialog"
      :tableList="tableList"
      @selectTable="table => updateTable(table, true)"
      @selectTakeaway="toggleTakeaway(true)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getTableName } from '@/utils'
import SelectTable from './SelectTable.vue'

export default {
  name: 'OrderDetails',

  components: {
    SelectTable
  },

  props: {
    isOrder: Boolean,
    isInvoice: Boolean,
    isValid: Boolean,
    tableCode: String,
    customerName: String,
    invoiceTableName: String,
    note: String,
    orderId: String
  },

  data: () => ({
    takeaway: false,
    valid: true,
    tableDialog: false
  }),

  computed: {
    ...mapState('common', ['tables']),
    ...mapGetters('order', ['orderTotal']),

    tableList() {
      const validTables = this.tables.filter(x => x.tableCode !== 'takeaway')
      const tableList = validTables.map(x => {
        if (
          this.isOrder &&
          x.orderInfo &&
          x.orderInfo.orderId !== this.orderId
        ) {
          x.disabled = true
        } else {
          x.disabled = false
        }
        return x
      })
      return tableList
    },

    tableName() {
      const table = this.tables.find(
        table => table.tableCode === this.tableCode
      )
      if (table) {
        return getTableName(table, this.isOrder)
      } else {
        return this.$t('order.select-table')
      }
    },

    selectTableColor() {
      if (this.orderId) {
        return 'purple lighten-4'
      } else if (this.tableCode) {
        return 'amber lighten-4'
      } else {
        return 'primary'
      }
    },

    showExtraInputs() {
      return this.isOrder || !this.orderId
    }
  },

  mounted() {
    if (this.tableCode === 'takeaway') {
      this.takeaway = true
    }
    this.checkSelectedTable()
    this.$mitt.on('initNewOrder', this.checkSelectedTable)
  },
  beforeDestroy() {
    this.$mitt.off('initNewOrder', this.checkSelectedTable)
  },

  watch: {
    orderTotal() {
      if (this.orderTotal === 0) {
        this.takeaway = false
      }
    }
  },

  methods: {
    toggleTakeaway(val) {
      if (val) {
        this.takeaway = val
      }
      let code
      if (this.takeaway) {
        this.valid = true
        code = 'takeaway'
      } else {
        this.valid = false
        code = null
      }
      this.updateTable(code)
    },

    checkSelectedTable() {
      if (!this.isInvoice && !this.isOrder && this.tables && !this.tableCode) {
        this.$nextTick().then(() => this.openSelectTable())
      }
    },

    updateTable(table, isSelected) {
      let code
      let orderId
      if (isSelected) {
        this.valid = true
        this.takeaway = false
        const { tableCode, orderInfo } = table
        code = tableCode
        if (orderInfo && !this.isOrder) {
          orderId = orderInfo.orderId
        }
      } else {
        code = table
        orderId = null
      }
      if (!this.isOrder) {
        this.$emit('update:orderId', orderId)
      }
      this.$emit('update:tableCode', code)
      this.$emit('update:isValid', this.valid)
    },

    openSelectTable() {
      this.tableDialog = true
    }
  }
}
</script>

<style scoped></style>
