import InvoiceService from '@/services/modules/InvoiceService'
import DishService from '@/services/modules/DishService'
import KitchenRequestService from '@/services/modules/KitchenRequestService'
import OrderService from '@/services/modules/OrderService'
import myApp from '../main'
import i18n from '@/i18n.js'
import {
  format,
  startOfDay,
  startOfMonth,
  endOfDay,
  endOfWeek,
  endOfMonth
} from 'date-fns'
import { enGB, vi } from 'date-fns/locale'
import latinize from 'latinize'

const locales = { en: enGB, vi }

export const fullTextSearch = (query, textFields = [], numberFields = []) => {
  const normalizeText = text => latinize(text).toLowerCase()

  let formattedQuery
  if ([null, undefined].includes(query)) {
    formattedQuery = ''
  } else {
    const sanitizedText = normalizeText(query).replace(
      /([.*+?^${}()|[\]\\])/g,
      '\\$1'
    )
    formattedQuery = new RegExp(`\\b${sanitizedText}\\b`)
  }
  let conditions = []
  const checkFunc = result => {
    if (result < 0) {
      conditions.push(false)
    } else {
      conditions.push(true)
    }
  }

  textFields.forEach(field => {
    const formattedField = normalizeText(field)
    const result = formattedField.search(formattedQuery)
    checkFunc(result)
  })

  numberFields.forEach(field => {
    const result = field.toString().search(formattedQuery)
    checkFunc(result)
  })

  if (conditions.includes(true)) {
    return true
  } else {
    return false
  }
}

export const hasRole = role => {
  const user = myApp.$auth.user
  const roles = user[process.env.VUE_APP_AUTH_ROLES]
  return roles.some(x => x === role)
}

export const formatDate = (date, formatStr = 'PP') => {
  return format(date, formatStr, {
    locale: locales[i18n.locale]
  })
}

export const displaySuccessMessage = text => {
  myApp.$notify({
    type: 'notif-success',
    text: i18n.t(text)
  })
}

export const displayErrorMessage = err => {
  function initErrorMessage(err) {
    if (err.response && err.response.data && err.response.data.message) {
      console.log(err)
      return err.response.data.message
    } else {
      console.log(err)
      return err
    }
  }

  if (err.message === 'Network Error') {
    myApp.$mitt.emit('networkError', 'error.unknown')
  } else {
    myApp.$notify({
      type: 'notif-error',
      text: initErrorMessage(err)
    })
  }
}

export const addOption = (dishId, option) => {
  return new Promise((resolve, reject) => {
    DishService.addOption(dishId, option)
      .then(res => {
        displaySuccessMessage('dish.message-add-option')
        resolve(res.data)
      })
      .catch(err => reject(err))
  })
}

export const updateOption = (dishId, option) => {
  return new Promise((resolve, reject) => {
    DishService.updateOption(dishId, option)
      .then(res => {
        displaySuccessMessage('dish.message-update-option')
        resolve(res.data)
      })
      .catch(err => reject(err))
  })
}

export const updateRequest = (request, type) => {
  return new Promise((resolve, reject) => {
    let successMessage
    if (type === 'completeRequest') {
      successMessage = 'kitchen-request.message-complete-request'
    } else if (type === 'editRequest') {
      successMessage = 'kitchen-request.message-edit-request'
    } else {
      successMessage = 'kitchen-request.message-cancel-request'
    }

    KitchenRequestService.updateKitchenRequest(request._id, request)
      .then(res => {
        myApp.$mitt.emit(type, res.data)
        displaySuccessMessage(successMessage)
        myApp.$socket.client.emit('update-request')
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const cancelOrder = order => {
  return new Promise((resolve, reject) => {
    const id = order._id
    OrderService.cancelOrder(id)
      .then(res => {
        myApp.$mitt.emit('cancelOrder', res.data)
        displaySuccessMessage('order.message-cancel-order')
        myApp.$socket.client.emit('update-request')
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const createInvoice = orderId => {
  return new Promise((resolve, reject) =>
    InvoiceService.createInvoice(orderId)
      .then(res => {
        displaySuccessMessage('invoice.message-create-invoice')
        const { tables } = myApp.$store.state.common
        const { tableCode } = res.data
        myApp.$store.dispatch('common/updateTable', {
          tableCode,
          orderInfo: null
        })
        myApp.$socket.client.emit('update-table', tables)
        myApp.$socket.client.emit('update-request')
        myApp.$mitt.emit('createInvoice', res.data)
        myApp.$socket.client.emit('update-invoice')
        resolve()
      })
      .catch(err => reject(err))
  )
}

export const updateInvoice = (id, invoice) => {
  return new Promise((resolve, reject) => {
    InvoiceService.updateInvoice(id, invoice)
      .then(res => {
        myApp.$mitt.emit('updateInvoice', res.data)
        displaySuccessMessage('invoice.message-update-invoice')
        myApp.$socket.client.emit('update-invoice')
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const getNewVal = (oldVal, increase, inKilogram) => {
  let newVal
  if (increase) {
    if (inKilogram) {
      newVal = (oldVal * 10 + 1) / 10
    } else {
      newVal = oldVal + 1
    }
  } else {
    if (inKilogram) {
      newVal = (oldVal * 10 - 1) / 10
    } else {
      newVal = oldVal - 1
    }
  }
  return newVal
}

export const displayQuantity = (quantity, inKilogram) => {
  let displayQt
  if (inKilogram) {
    if (quantity < 1) {
      if (i18n.locale === 'vi') {
        displayQt = `${quantity * 10} ${i18n.t('common.gram')}`
      } else {
        displayQt = `${quantity * 1000} ${i18n.t('common.gram')}`
      }
    } else {
      displayQt = `${quantity} ${i18n.t('common.kilogram')}`
    }
  } else {
    displayQt = quantity
  }
  return displayQt
}

export const getPriceModifier = dish => {
  const { runCampaign, campaignDetails } = myApp.$store.state.common
  const { price } = dish

  let priceMod = 0
  if (runCampaign) {
    for (let i = 0; i < campaignDetails.length; i++) {
      const { tier, modifier } = campaignDetails[i]
      if (price < tier) {
        break
      } else {
        priceMod = modifier
      }
    }
  }
  return priceMod
}

export const adjustOptionPrice = (option, priceTBD) => {
  if (priceTBD) {
    option.price = null
  } else {
    const priceModifier = getPriceModifier(option)
    option.priceModifier = priceModifier
    option.price += priceModifier
  }
  return option
}

export const reprocessDish = dish => {
  const { options, priceTBD, fromKitchen } = dish
  options.forEach(option => {
    option.fromKitchen = fromKitchen
    adjustOptionPrice(option, priceTBD)
  })
  return dish
}

export const createForm = (object, file) => {
  const form = new FormData()
  for (const key in object) {
    form.append(key, object[key])
  }
  if (file) {
    form.append('img', file)
  }
  return form
}

export const createValidationRules = fieldObject => {
  const rules = []
  const { label, required, characterLimit, sizeLimit } = fieldObject
  if (required) {
    const requiredRule = v =>
      !!v || `${i18n.t(label)} ${i18n.t('common.is-required')}`
    rules.push(requiredRule)
    if (characterLimit) {
      const requiredCharLimitRule = v =>
        (v && v.length <= characterLimit) ||
        `${i18n.t(label)} ${i18n.t(
          'common.is-less-than'
        )} ${characterLimit} ${i18n.t('common.characters')}`
      rules.push(requiredCharLimitRule)
    } else if (sizeLimit) {
      const requiredSizeLimitRule = v =>
        (v && v.size < 1024 * 1024 * sizeLimit) ||
        `${i18n.t(label)} ${i18n.t('common.is-less-than')} ${sizeLimit} MB`
      rules.push(requiredSizeLimitRule)
    }
  } else if (characterLimit) {
    const charLimitRule = v =>
      !v ||
      v.length <= characterLimit ||
      `${i18n.t(label)} ${i18n.t(
        'common.is-less-than'
      )} ${characterLimit} ${i18n.t('common.characters')}`
    rules.push(charLimitRule)
  } else if (sizeLimit) {
    const sizeLimitRule = v =>
      !v ||
      v.size < 1024 * 1024 * sizeLimit ||
      `${i18n.t(label)} ${i18n.t('common.is-less-than')} ${sizeLimit} MB`
    rules.push(sizeLimitRule)
  }
  return rules
}

export const makePrint = (content, copies = 1) => {
  // Get all stylesheets HTML
  let stylesHtml = ''
  for (const node of [
    ...document.querySelectorAll('link[rel="stylesheet"], style')
  ]) {
    stylesHtml += node.outerHTML
  }

  // Open the print window
  const WinPrint = window.open(
    '',
    '',
    'left=0,top=0,width=800,height=1800,toolbar=0,scrollbars=0,status=0'
  )

  const pageBreak = '<div style="page-break-after: always;"></div>'
  let printContent = content
  for (let i = 1; i < copies; i++) {
    printContent += pageBreak
    printContent += content
  }

  WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${printContent}
          </body>
        </html>`)

  const noPrintEls = WinPrint.document.querySelectorAll('.no-print')
  noPrintEls.forEach(el => el.remove())
  WinPrint.document.close()
  WinPrint.focus()
  WinPrint.onafterprint = WinPrint.close
  WinPrint.onload = WinPrint.print
}

export const getTableName = (table, shortName) => {
  const { tableName, orderInfo } = table
  if (orderInfo && !shortName) {
    const { customerName, createdAt } = orderInfo
    if (customerName) {
      return `${tableName} - ${customerName}`
    } else {
      return `${tableName} - ${myApp.$helper.formattedDate(createdAt, true)}`
    }
  } else {
    return tableName
  }
}

export const getInvoiceTotal = entries => {
  if (Array.isArray(entries)) {
    return entries.reduce(
      (acc, item) => acc + item.finalQuantity * item.price,
      0
    )
  } else {
    return 0
  }
}

export const setTimePeriod = (date, byMonth) => {
  let startDate, endDate, from, to
  if (date && date.length > 0) {
    if (date.length === 1) {
      startDate = new Date(date)
      endDate = new Date(date)
    } else if (date.length === 2) {
      startDate = new Date(date[0])
      endDate = new Date(date[1])
    }
    if (byMonth) {
      from = startOfMonth(startDate).toISOString()
      to = endOfMonth(endDate).toISOString()
    } else {
      from = startOfDay(startDate).toISOString()
      to = endOfDay(endDate).toISOString()
    }
  } else {
    from = ''
    to = ''
  }
  return { from, to }
}

export const setAutoRange = (rangeType, selectedDate) => {
  let dates
  if (Array.isArray(selectedDate)) {
    dates = selectedDate
  } else if (selectedDate) {
    dates = [selectedDate.valueOf()]
    if (rangeType === 'weekly') {
      const endWeek = endOfWeek(new Date(selectedDate), {
        weekStartsOn: 1
      })
      const endWeekString = formatDate(endWeek, 'yyyy-MM-dd')
      dates.push(endWeekString)
    }
  } else {
    dates = null
  }
  return dates
}

export const updateAutoRange = (selectMonthPicker, date) => {
  let type
  if (selectMonthPicker) {
    type = 'monthly'
  } else {
    type = null
  }
  let dateRange
  if (Array.isArray(date)) {
    dateRange = [...date]
  } else {
    dateRange = null
  }
  myApp.$store.commit('common/SET_AUTO_RANGE', {
    type,
    date: dateRange
  })
}
