import { apiClientProtected } from '..'
import { setTimePeriod } from '@/utils'

const route = '/orders'

export default {
  getList(orderType, conditions) {
    let start = ''
    let end = ''
    if (conditions) {
      const { date, byMonth } = conditions
      const { from, to } = setTimePeriod(date, byMonth)
      start = from
      end = to
    }
    return apiClientProtected.get(
      `${route}/${orderType}?from=${start}&to=${end}`
    )
  },
  submitOrder(tableCode, customerName, dishes) {
    return apiClientProtected.post(route, {
      tableCode,
      customerName,
      dishes
    })
  },
  getOrder(id) {
    return apiClientProtected.get(`${route}/${id}`)
  },
  updateOrder(id, order) {
    return apiClientProtected.put(`${route}/${id}`, order)
  },
  updatePrintedOrder(id) {
    return apiClientProtected.post(`${route}/print/${id}`)
  },
  cancelOrder(id) {
    return apiClientProtected.delete(`${route}/${id}`)
  }
}
