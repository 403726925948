import Vue from 'vue'
import Notifications from 'vue-notification'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import App from './App.vue'
import { Auth0Plugin } from './auth'
import i18n from './i18n'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'nprogress/nprogress.css'
import mitt from 'mitt'
import helpers from './plugins/helpers'
import VueGtag from 'vue-gtag'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import Clipboard from 'v-clipboard'

const socket = io(process.env.VUE_APP_SOCKET_IO, {
  withCredentials: true,
  transports: ['websocket', 'polling', 'flashsocket']
})

Vue.use(Clipboard)

Vue.use(VueSocketIOExt, socket, { store })

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.use(Notifications)

Vue.component('v-text-field', VTextField)

Vue.use(VCurrencyField, {
  decimalLength: 0,
  min: null,
  max: 999999999,
  defaultValue: null
})

Vue.use(Loading)

// Find and register all base components
const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.prototype.$mitt = mitt()
Vue.mitt = mitt()

const localPlugin = {
  install() {
    Vue.helper = helpers
    Vue.prototype.$helper = helpers
  }
}

Vue.use(localPlugin)

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GTAG }
  },
  router
)

Vue.config.productionTip = false

const myApp = new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default myApp
